.companyItem {
  text-decoration: none;
  border: 3px solid rgba(0, 118, 137, 0.766);
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: rgba(0, 118, 137, 0.766);
  font-weight: 500;
}

.companyItem:hover {
  background-color: rgba(0, 118, 137, 0.766);
  color: white;
  transition: 300ms;
  color: white;
  transition: 300ms;
}

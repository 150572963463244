.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-content {
    background-color: #fff;
    border-radius: 20px;
    min-width: 40%;
    max-width: 60%;
    min-height: 300px;
}

.modal-header {
    padding: 10px;
    height: 40px;
    background-color: aliceblue;
    display: flex;  
    justify-content: space-between;
    color: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.modal-title{
    margin-top: 10px;
}
.close-Btn{
    background-color: aliceblue;
    color: black;
    border: none;
    margin-top: 0px;
    height: 13px;
}

.close-Btn:hover {
    transform: scale(1.3);
}

/** QUESTIONNAIRE */
.modal-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.modal-section {
    margin: 0px;
    border-bottom: 1px solid grey;
}
.question {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 3px;
}
.answer{
    font-size: 14px;
    padding: 0px 10px;
    margin: 0px;
}

.modal-section:last-child{
    padding-bottom: 30px;
}

/** ACTION PLAN */
.modal-body > textarea {
    margin: 10px;
    min-width: 280px;
    min-height: 150px;
    align-self: center;
}
.datepicker-container {
    text-align: center;
}
.btn-container{
    margin: 10px;

}


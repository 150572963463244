.modalBackdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modalCon {
    padding: 10px;
    max-width: 580px;
    margin: 2rem auto;
    background: #fff;
    border-radius: 10px;
}

.modalCon h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 1rem;
}

.modalCon p {
    font-size: 22px;
    margin: 4rem 0;
}
.logoBar {
  background: #fff;
}

.homePage {
  height: 85.1vh;
}


.homeContent {
  width: 100%;
  height: 100%;
  background: url("https://acorn-marketing.co.uk/wp-content/uploads/2022/04/New-header-pic-copy2.jpg")
    no-repeat;
  background-size: cover;
  /* background-position: -10px -150px; */
}

.formTextCon {
  color: rgb(255, 255, 255);
  background: #007883;
  border-radius: 10px;
}

.homeText {
  position: relative;
  font-family: "Montserrat-Light";
  text-align: start;

  border-radius: 3px;
}

.loginBtn {
  padding: 5px 13px;
  background-color: aliceblue;
  border-radius: 10px;
  border: 2px solid aliceblue;
  color: rgba(0, 118, 137, 0.766);
  font-weight: 600;
  text-decoration: none;
}
.loginBtn:hover {
  border: 2px solid rgba(0, 118, 137, 0.766);
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  .homeContent {
    background-position: -400px -100px;
  }
  .homePage {
    height: 94vh;
  }
  .formTextCon{
    margin-top: 200px;
  }
}

.footerCon {
  min-height: 300px;
  color: #fff;
  background-color: #004e56;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/footerbg-1.jpg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
}

.footerHeader {
  font-family: "GoldenPlains";
  font-size: 2rem;
  color: #57b8a6;
}

.footerPolicy{
  font-weight: 700;
  cursor: pointer;
}

.footerLinks a,
.footerContact {
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat-Light";
  font-size: 18px;
}

.footerBlurb {
  color: #fff;
  font-size: 14px;
}

.footerAwards {
  min-height: 300px;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2020/01/footer-awards-2.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 30px;
}

.footerIcon {
  color: #fff;
  cursor: pointer;
}

.coursesImgCon {
  max-height: 500px;
  min-height: 500px;
}

.coursesImgCon img {
  max-width: 100%;
  height: 100%;
}

.topRowCon {
  background: #fff;
}
.topRow {
  background-color: #ddebeb;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/portfoliobg.jpg");
  background-repeat: no-repeat;
}

.bottomRow {
  background-color: #ddebeb;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/about-bottom.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
}

.coursesImg1 {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2022/05/what-we-do1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.coursesImg2 {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/whatwedo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cprof-container {
  padding: 10px;
}
.cprof-member-container > p {
  margin-left: 15px;
  border-bottom: 1px solid rgba(0, 118, 137, 0.766);
  width: 250px;
  display: flex;
  justify-content: space-between;
}
.cprof-member-container > p > button {
  background: none;
  padding: 3px 10px;
  border-radius: 20px;
  border: 2px solid white;
  font-weight: 600;
  color: rgba(0, 118, 137, 0.766);
}
.cprof-member-container > p > button:hover {
  border: 2px solid rgba(0, 118, 137, 0.766);
  cursor: pointer;
}

.cprof-actionp-top {
  display: flex;
  align-items: center;
}

.add-actionp-icon {
  background: white;
  font-size: 23px;
  margin: 10px;
  color: rgba(0, 118, 137, 0.766);
  cursor: pointer;
}

.add-actionp-icon:hover {
  color: rgba(0, 54, 63, 0.766);
}

.topNav {
  background: var(--primary);
  padding: 0;
  margin: 0;
}

.navLink {
  padding: 1rem;
  font-family: "Montserrat-Light";
  text-decoration: none;
  color: #5dbfab;
  font-size: 20px;
  font-weight: 800;
  border: 2px solid #fff;
  transition: all 0.2s;
}

.active {
  border: 2px solid #5dbfab;
}

.navLink:hover {
  border: 2px solid #5dbfab;
  color: #5dbfab;
  cursor: pointer;
}

.logoCon {
  max-height: 220px;
  max-width: 220px;
  cursor: pointer;
}

@media only screen and (max-width: 1450px) {
  .navLink {
    padding: 0;

    font-size: 16px;
  }

  .navLinkCon {
    padding: 1rem 0;
  }
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "GoldenPlains";
  src: local("GoldenPlains"),
    url("./assets/fonts/GoldenPlains.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
    url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.form-floating label {
  color: black;
}

.active {
  border: 2px solid #5dbfab;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(199, 199, 199) rgb(201, 201, 201);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(237, 237, 237);
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(0, 119, 137);
  border-radius: 20px;
  border: 3px solid rgb(0, 119, 137);
}

.playIcon {
  font-size: 170px;
  color: rgba(128, 128, 128, 0.143);
}
.playerCon {
  min-height: 540px;
  background-color: aliceblue;
}

.player {
  height: 540px;
  background-color: aliceblue;
}

.detailsCon {
  height: 540px;
  overflow: auto;
}

.itemCon {
  background-color: #007883;
  color: #fff;
  border-radius: 8px;
}
.detailsName {
  font-family: "Montserrat-Light";
}

.video-title {
  margin-left: 10%;
  font-weight: 500;
}
.playBtn {
  color: rgba(0, 118, 137, 0.766);
  border: 2px solid rgba(0, 118, 137, 0.766);
  border-radius: 10px;
}
.playBtn:hover {
  border: 2px solid rgba(0, 118, 137, 0.766);
}

.videosBottomRow {
  background-color: #ddebeb;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/about-bottom.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
}


.actionplan-container{
    border-bottom: solid rgba(0, 118, 137, 0.766);
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.actionplan-task{
    width: 60%;
    font-weight: 500;
}
.actionplan-duedate{
    font-size: 14px;
}
.actionplan-icon-done{
    color: green;
}
.actionplan-icon-undone{
    color: red;
}
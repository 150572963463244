.signout {
  text-align: right;
}
.sideBar {
  position: absolute;
  top: 0;
}
.welcome-message-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-img-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.welcome-img-container img {
  max-width: 94%;
  border-radius: 2px;
}
.welcomeMessage {
  padding: 0px 30px;
}
.welcomeMessage h1 {
  font-family: "GoldenPlains";
  font-size: 65px;
  color: rgb(0, 119, 137);
}
.welcomeMessage p {
  font-family: "Montserrat-Light";
}

.welcomeSubTitle {
  font-family: "GoldenPlains";
  font-size: 40px;
  color: rgb(0, 119, 137);
}

.navCard {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navCardName {
  font-family: "Montserrat-Light";
  font-size: 25px;
  font-weight: 500;
  color: rgba(0, 118, 137, 0.766);
  min-width: 400px;
}

.navCard img {
  border-radius: 100%;
}

@media only screen and (max-width: 600px) {
  .navCardName {
    font-size: 20px;
    min-width: 200px;
  }
}

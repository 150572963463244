.App {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.footer {
  justify-self: end;
  position: relative;
  bottom: 0;
  left: 0;
}

.pageTitle {
  font-family: "GoldenPlains";
  font-size: 65px;
  font-weight: 500;
  color: rgb(0, 119, 137);
}

.pageTitleLight {
  font-family: "GoldenPlains";
  font-size: 65px;
  font-weight: 500;
  color: rgb(255, 255, 255);
}

.primaryTitle {
  font-family: "Montserrat-Light";
  font-weight: 700;
  color: rgb(0, 119, 137);
}

.primaryText {
  font-family: "Montserrat-Light";
  color: rgba(0, 118, 137, 0.766);
}

.secondaryText {
  font-family: "GoldenPlains";
  font-size: 40px;
  font-weight: 600;
  color: rgb(0, 119, 137);
}

.primaryBtn {
  min-width: 160px;
  padding: 0.5rem;

  border: solid 1px #5dbfab;
  font-family: "Montserrat-Light";
  text-decoration: none;

  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s;
  cursor: pointer;
  background: #5dbfab;
  color: #fff;
}

.primaryBtn:hover {
  background: #3c7b6e;
  color: #ffffff;
}

.secondaryBtn {
  min-width: 160px;
  padding: 0.5rem;

  border: solid 1px #5dbfab;
  font-family: "Montserrat-Light";
  text-decoration: none;

  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s;
  cursor: pointer;
  background: #ffffff;
  color: #5dbfab;
}

.secondaryBtn:hover {
  background: #3c7b6e;
  color: #ffffff;
}

.secondaryBg {
  background-color: aliceblue;
}

textarea.form-control {
  min-height: 200px;
}

.form-control:focus {
  border-color: #5dbfab;
  box-shadow: 0 0 0 0.2rem #5dbfab43;
}

input .taskCheck {
  background: rgb(0, 119, 137);
  color: rgb(0, 119, 137);
  padding: 4rem;
}

.btn-primary {
  background: #5dbfab;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background: #3c7b6e;
  color: #ffffff;
}

.btn-primary:active:focus {
  background: #3c7b6e;
  color: #ffffff;
}

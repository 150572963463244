.addcompany-container{
    max-width: 400px;
    text-align: center;

}
.addcompany-title{
    color: rgba(0, 118, 137, 0.766);
}
.addcompany-inuput-container{
    display: flex;
    flex-direction: column;
    width: 280px;
    padding: 10px;
}
.addcompany-inputfield{
    margin: 5px;
    border: 1px solid rgba(0, 118, 137, 0.766);
    border-radius: 20px;
    padding: 5px;
    margin: 10px;
}
.addcompany-addBtn{
    align-self: center;
    background: aliceblue;
    border: 3px solid white;
    border-radius: 20px;
    padding: 6px 20px;
    margin-top: 10px;
    font-weight: bolder;
    color: rgba(0, 118, 137, 0.766);
}
.addcompany-addBtn:hover{
    border: 2px solid rgba(0, 118, 137, 0.766);
    transition: 1.5s;
    cursor: pointer;
}
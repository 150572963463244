.checkin-container > button{
    background: none;
    padding: 3px 10px;
    border-radius: 20px;
    border: 2px solid white;
    font-weight: 600;
    color: rgba(0, 118, 137, 0.766);
}
.checkin-container > button:hover{
    border: 2px solid rgba(0, 118, 137, 0.766);
    cursor: pointer;
}
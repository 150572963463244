.form-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  padding-top: 100px;
}
.login-form-title {
  font-family: "GoldenPlains";
  font-size: 65px;
  font-weight: 500;
  align-self: center;
}

.login-form-subtitle {
  font-family: "Montserrat-Light";
  font-weight: 500;
  align-self: center;
}
.form-input {
  border: 1px solid rgba(0, 118, 137, 0.766);
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
}

.form-input:focus {
  outline: none;
  border: 2px solid rgba(0, 118, 137, 0.766);
}
.form-btn {
  align-self: center;
  background: white;
  border: 3px solid white;
  padding: 10px;
  margin-top: 30px;
  width: 90px;
  font-weight: bolder;
  color: rgba(0, 118, 137, 0.766);
}
.form-btn:hover {
  border: 3px solid rgba(0, 118, 137, 0.766);
  transition: 1.5s;
  cursor: pointer;
}
.reg-form-title {
  align-self: center;
}
.reg-form-field {
  align-self: center;
}

.loginErr {
  background-color: aliceblue;

  margin-top: 0.5rem;
  border-radius: 5px;
}

@media only screen and (max-width: 950px) {
  .login-form-title {
    font-family: "GoldenPlains";
    font-size: 45px;
    font-weight: 500;
    align-self: center;
  }
  .login-form-subtitle {
    font-size: 18px;
  }
}

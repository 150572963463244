.downloadCon {
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/portfoliobg.jpg");
}

.downloadSectionCon {
  background-color: aliceblue;
}

.blurbCon {
  background-color: #fff;
}

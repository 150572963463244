.apImage {
  background-color: #ddebeb;
  background-image: url("https://acorn-marketing.co.uk/wp-content/uploads/2019/12/whatwedobg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;


}

.tHead {
  background-color: rgb(0, 119, 137);
  color: white;
  font-family: "Montserrat-Light";
  font-weight: 700;
}

.tableCon {
  border-radius: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
}

.tRow {
  background-color: #fff;
  font-family: "Montserrat-Light";
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/* The checkbox */
.checkbox > input {
  /*
      Remove the default appearance.
    */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  /*
      Set the size of the checkbox.
    */
  width: 20px;
  height: 20px;

  cursor: pointer;
  box-shadow: 0 0 0 2px rgb(0, 119, 137); /* Outer border */
  border: 3px solid aliceblue; /* Inner border */
}

/* The checkbox - when checked */
.checkbox > input:checked {
  background-color: rgb(0, 119, 137);
}

.actionCon {
  min-height: 70vh;
}

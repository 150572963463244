.topTipTitle {
  font-family: "GoldenPlains";
  font-size: 65px;
  font-weight: 500;
  color: rgba(0, 118, 137, 0.766);
}

.selectedCat {
  background: #3c7b6e;
  color: #fff;
}

.categoryList {
  overflow-x: auto;
  background: #ddebeb;
  background-size: cover;
}

